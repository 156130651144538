<template>
  <el-card>
    <el-row :gutter="20" class="header">
      <el-col :span="7">
        <el-input placeholder="请输入医生姓名..." clearable v-model="queryForm.query"></el-input>
      </el-col>
      <el-button type="primary" :icon="Search" @click="initSmallTypeList">搜索</el-button>
      <el-button type="primary" @click="handleDialogValue()">添加乡镇医生</el-button>
    </el-row>
    <el-table :data="tableData" height="550" stripe style="width: 100%">
      <el-table-column prop="townshipdoctorId" label="编号" />
      <el-table-column prop="townshipdoctorName" label="姓名" />
      <el-table-column prop="townshipdoctorPhone" label="手机号" />
      <el-table-column prop="townshipName" label="乡镇" />
      <el-table-column prop="village" label="村" />
      <el-table-column prop="integral" label="积分" />


<!--      <el-table-column prop="id" label="#ID" width="80" />-->
<!--      <el-table-column prop="name" label="商品小类名称" width="200" />-->
<!--      <el-table-column prop="bigType" :formatter="bigTypeFormatter" label="所属大类" width="200">-->
<!--      </el-table-column>-->
<!--      <el-table-column prop="remark" label="商品小类描述"/>-->
      <el-table-column prop="action" label="操作" width="400">
        <template v-slot="scope">
          <el-button type="primary" :icon="Edit" @click="handleDialogValue(scope.row)">编辑</el-button>
          <el-button type="danger" :icon="Delete" @click="handleDelete(scope.row)">删除</el-button>
          <el-button type="success" :icon="Edit" @click="Change_password(scope.row)">修改密码</el-button>
        </template>

      </el-table-column>

    </el-table>

    <el-pagination
      v-model:currentPage="queryForm.pageNum"
      :page-sizes="[10, 20, 30, 40,50]"
      :page-size="queryForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>

  <Dialog v-model="dialogVisible" :dialogTitle="dialogTitle" @initSmallTypeList="initSmallTypeList" :id="id"  :dialogValue="dialogValue"/>
<!--  <el-dialog-->
<!--    title="修改密码"-->
<!--    :visible.sync="password_dialog_Visible"-->
<!--    :model-value="password_dialog_Visible"-->
<!--    width="30%"-->
<!--    :before-close="handleClose">-->
<!--    <el-input v-model="password"></el-input>-->
<!--    <span>这是一段信息</span>-->

<!--  </el-dialog>-->



  <el-dialog
    title="修改密码"
    v-model:visible="password_dialog_Visible"
    :model-value="password_dialog_Visible"
    width="25%"

    @close="password_dialog_Visible = false">
    <el-row :gutter="20">
      <el-col :span="5">
        <span>原密码:</span>
      </el-col>
      <el-col :span="12">
        <el-input disabled v-model="password" type="password" placeholder="原密码" width=10%></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <span>新密码:</span>
      </el-col>
      <el-col :span="12">
        <el-input v-model="newPassword" placeholder="请输入新密码"></el-input>
      </el-col>
    </el-row>

<!--    <span style="display: flex">-->
<!--      <span>原密码</span>-->
<!--      <el-input disabled v-model="password" placeholder="原密码" width=10%></el-input>-->
<!--    </span>-->
<!--    <span>新密码-->
<!--      <el-input v-model="newPassword" placeholder="请输入新密码"></el-input>-->
<!--    </span>-->
    <template #footer>
      <span  class="dialog-footer" >
          <el-button @click="password_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="report_newPassword">确 定</el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script setup>

import { Search, Edit, Delete, View } from "@element-plus/icons-vue";
import { ref } from 'vue'
import  axios from '@/util/axios'
import {getServerUrl} from "@/config/sys";
import Dialog from './components/dialog'

import {ElMessageBox,ElMessage} from 'element-plus'

const queryForm=ref({
  query:'',
  pageNum:1,
  pageSize:10
})



const total=ref(0)

const id=ref(-1)

const tableData=ref([
])

const dialogValue=ref({})

const dialogTitle=ref('')

const password_dialog_Visible=ref(false)



const initSmallTypeList=async()=>{
  const res=await axios.post("township-user/list",queryForm.value);
  console.log(res)
  tableData.value=res.data.data.map.townshipUserList;
  total.value=res.data.data.map.total;
  // console.log('xxx')
  // const res=await axios.post("admin/smallType/list",queryForm.value);
  // tableData.value=res.data.smallTypeList;
  // total.value=res.data.total;
}

initSmallTypeList();

const dialogVisible=ref(false)



const handleSizeChange=(pageSize)=>{
  queryForm.value.pageNum=1;
  queryForm.value.pageSize=pageSize;
  initSmallTypeList();
}

const handleCurrentChange=(pageNum)=>{
  queryForm.value.pageNum=pageNum;
  initSmallTypeList();
}


const handleDialogValue = (row) => {
  // initSmallTypeList();
  console.log('aaa'+id.value)
  id.value=-2;
  console.log('bbb'+row)
  // console.log(row)
  // console.log('bbb'+id.value)
  if(row){
    // console.log('修改医生信息'+row)
    // console.log(row)
    //
    // dialogValue.value=JSON.parse(JSON.stringify(row));
    // console.log('hhh'+row.townshipdoctorId)
    // id.value=row.townshipdoctorId;
    //
    // console.log('kkk'+id.value)
    //
    // dialogTitle.value="修改医生信息"


    dialogValue.value=JSON.parse(JSON.stringify(row));
    console.log(dialogValue.value)
    console.log(row)
    console.log('hhh'+row.townshipdoctorId)
    id.value=row.townshipdoctorId;
    // id.value=1;
    // id.value=row.id;
    console.log('kkk'+id.value)

    dialogTitle.value="修改医生信息"
  }else{
    // dialogValue.value={
    //   bigType:{
    //     id:""
    //   }
    // }

    id.value=-1;
    console.log("添加医生")
    console.log(id)
    dialogTitle.value="添加医生"
    dialogTitle.value="添加乡镇医生"
  }
  dialogVisible.value=true;
}

const handleDelete = (row) => {

  ElMessageBox.confirm(
    '您确定要删除这条记录吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {

      console.log("townshipdoctorId="+row.townshipdoctorId)
      console.log(row)
      let res=await axios.del("township-user/removeById?townshipdoctorId="+row.townshipdoctorId);
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '删除成功！',
        });
        initSmallTypeList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.msg,
        });
      }

    })
    .catch(() => {

    })
}

const password=ref()
const newPassword=ref()
const userName=ref()
const password_message=ref({
  password:'',
  newPassword:'',
  userName:''
})

// 点击修改密码 弹出对话框
const Change_password = async(row) => {
  password_dialog_Visible.value=true
  console.log("打印该行")
  console.log(row)
  console.log(row.userId)
  const res=await axios.get("user/getUser?userId="+row.userId);
  console.log(res)
  password.value=res.data.data.user.password
  userName.value=res.data.data.user.userName
  password_message.value.password=res.data.data.user.password
  password_message.value.userName=res.data.data.user.userName
  console.log(password_message.value.password)
  console.log(password_message.value.userName)
}

// 点击确定 提交新密码
const report_newPassword = async(row) => {
  console.log("打印新密码")
  console.log(newPassword)
  if( newPassword.value == null ){
    ElMessage({
      type: 'warning',
      message: '新密码不可为空！',
    });
    return
  }
  else if( newPassword.value.length <= 5){
    ElMessage({
      type: 'warning',
      message: '新密码不可低于6位！',
    });
    return
  }
  password_message.value.newPassword=newPassword.value
  console.log(password_message.value.newPassword)

  // const res=await axios.post("user/password?userName="+userName.value+"&password="+password.value+"&newPassword="+newPassword.value);
  const res=await axios.post("user/password",password_message.value);
  console.log(res)
  if( res.data.code == 0 ){
    ElMessage({
      type: 'success',
      message: '修改成功！',
    });
    password_dialog_Visible.value=false

  }

}



const bigTypeFormatter = (row) => {
  return row.bigType.name
}


</script>

<style lang="scss" scoped>

.el-row {
  margin-bottom: 20px;

}
.header{
  padding-bottom: 16px;
  box-sizing: border-box;
}

.el-pagination{
  padding-top: 15px;
  box-sizing: border-box;
}


</style>
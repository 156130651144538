<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="30%"
    @close="handleClose"
  >
    <el-form ref="formRef" :model="form" label-width="100px" :rules="rules">
      <el-form-item label="姓名" prop="townshipdoctorName">
        <el-input v-model="form.townshipdoctorName"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="townshipdoctorPhone">
        <el-input v-model="form.townshipdoctorPhone"></el-input>
      </el-form-item>

      <el-form-item label="所属乡镇">
        <el-select v-model="form.townshipName" class="m-2" placeholder="请选择..." @focus="load_township" @change="change_township">
          <el-option
            v-for="item in range_township"
            :key="item"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所属村">
        <el-select v-model="form.village" class="m-2" placeholder="请选择..." @focus="load_village">
          <el-option
            v-for="item in range_village"
            :key="item"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>



<!--      <el-form-item label="所属乡镇">-->
<!--        <el-cascader-->
<!--          :options="range_township"-->

<!--          :props="props_township"-->
<!--          placeholder="请选择乡镇"-->
<!--          @focus="load_township"-->

<!--          @active-item-change="load_village"-->
<!--          change-on-select="false"-->
<!--        ></el-cascader>-->
<!--      </el-form-item>-->

<!--      <el-form-item label="所属乡镇">-->
<!--        <el-cascader-->
<!--          :options="range_township"-->

<!--          @change="load_village"-->
<!--          :props="props_township"-->
<!--          change-on-select="false"-->
<!--        ></el-cascader>-->
<!--      </el-form-item>-->




      <el-form-item label="情况简介" prop="remark">
        <el-input
          v-model="form.remark"
          :rows="4"
          type="textarea"
        />
      </el-form-item>


    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits,ref ,defineProps,watch} from 'vue'
import axios from "@/util/axios";
import { ElMessage } from "element-plus";

// const range_village=ref([
//   '中医院','贡江镇','盘古山镇','禾丰镇','祁禄山镇','梓山镇','银坑镇','岭背镇','罗坳镇','罗江乡','小溪乡','利村乡','新陂乡','靖石乡','黄麟乡','沙心乡','宽田乡','葛坳乡','桥头乡','马安乡','仙下乡','车溪乡'
// ])

const props_township=ref({
  value: 'label',
  children: 'village'

})
const range_township=ref([
  {
    label: '',
  },
])

const range_village=ref([
  {
    label: '',
  },
])



const load_township=async()=>{
  // console.log('active item:', val);
  const res=await axios.get("township/findAll");
  console.log("查询所有乡镇")
  console.log(res)
  range_township.value=res.data.data.townshipList.message
  console.log("打印range_township")
  console.log(range_township.value)
  console.log(range_township.value.length)
  for( let i = 0; i < res.data.data.townshipList.message.length; i++){
    range_township.value[i].label=res.data.data.townshipList.message[i].townshipName
    console.log(range_township.value[i].label)
  }
}

// 编辑医生信息时，需修改乡镇和村，当选择好乡镇后，自动清除旧的村
const change_township=async()=>{
  form.value.village=null
}


const all_village=ref([

])

const load_village=async()=>{
  // console.log("选中的乡镇是")
  // console.log(val)
  // console.log("选中的乡镇是"+val)
  const val = townshipName.value
  const res=await axios.get("township/findAllVillage?townshipName="+val);
  console.log(res)

  range_village.value=res.data.data.townshipList.message
  console.log("打印range_village")
  console.log(range_village.value)
  console.log(range_township.value.length)
  for( let i = 0; i < res.data.data.townshipList.message.length; i++){
    range_village.value[i].label=res.data.data.townshipList.message[i].village
    console.log(range_village.value[i].label)
  }

  // 将获取到的所有村赋值到数组中
  // for( let i = 0; i < res.data.data.townshipList.message.length; i++){
  //   all_village[i]=res.data.data.townshipList.message[i].village
  // }
  // console.log("该乡镇下面的是：")
  // console.log(all_village)


  // for( let i = 0; i < range_township.value.length; i++){
  //   if( val == range_township.value[i].label ){
  //     // 根据下标找出对应乡镇，将所有村赋值给该乡镇
  //     console.log(i)
  //     range_township.value[i].village=res.data.data.townshipList.message
  //     console.log("该乡镇下面的村是：")
  //     console.log(all_village)
  //     console.log(range_township.value[i].village)
  //     for( let j = 0; j < range_township.value[i].village.length; j++){
  //       console.log("打印村是：")
  //       console.log(range_township.value[i].village[j].label)
  //       console.log(all_village[j])
  //       range_township.value[i].village[j].label=all_village[j]
  //       console.log(range_township.value[i].village[j].label)
  //       console.log(all_village[j])
  //       range_township.value[i].village[j] = [{
  //         label: all_village[j]
  //       }];
  //     }
  //     console.log(range_township.value[i].village)
  //
  //     console.log(range_township.value[i].village)
  //   }
  // }



}

const range_village1=ref([
  {
    label: '江苏',
    cities: []
  }, {
    label: '浙江',
    cities: []
  }
])

// options2: [{
//   label: '江苏',
//   cities: []
// }, {
//   label: '浙江',
//   cities: []
// }],



const props=defineProps({
  dialogTitle:{
    type:String,
    default:'',
    required:true
  },
  id:{
    type:Number,
    default:-1,
    required:true
  },
  dialogValue:{
    type:Object,
    default:()=>{}
  }
})


const form=ref({
  townshipdoctorId:'',
  id:-1,
  name:'',
  townshipdoctorName:'',
  doctorPhone:'',
  townshipdoctorPhone:'',
  townshipName:'',
  remark:'',
  bigType:{
    id:""
  }
})


const initFormData=async(id)=>{
  const res=await axios.get("township-user/contacts/"+id);
  console.log('ksdadsadad')
  console.log(res)
  console.log(res.data.townshipUser.townshipdoctorName)

  form.value=res.data.townshipUser;
  // form.value.townshipdoctorName=res.data.townshipUser.townshipdoctorName;
  // form.value.townshipdoctorPhone=res.data.townshipUser.townshipdoctorPhone;
  // form.value.townshipdoctorName=res.data.townshipUser.townshipdoctorName;

}

const townshipName=ref({
})

watch(
  // ()=>props.id,
  // ()=>{
  //   console.log("==============");
  //   console.log("dialogTitle="+props.dialogTitle);
  //   console.log("id="+props.id);
  //   let id=props.id
  //
  //   form.value.id=id;
  //
  //   if(id!=-1){
  //     // 请求后端 获取数据
  //     initFormData(id)
  //   }
  // },
  // {deep:true,immediate:true}


  ()=>props.dialogValue,
  ()=>{
    console.log("dialogValue222="+props.dialogValue);
    console.log(props.dialogValue);
    form.value=props.dialogValue;
    townshipName.value=form.value.townshipName
  },
  {deep:true,immediate:true}
)


const emits=defineEmits(['update:modelValue','initSmallTypeList'])

const formRef=ref(null)


const handleClose = () => {
  // console.log("测试关闭对话框")
  // console.log(form.value)
  emits('update:modelValue',false)
  formRef.value.resetFields();

  // console.log(form.value)
}




const rules=ref({
  name:[
    { required: true, message: '请输入医生姓名！', }
  ],
  remark:[
    { required: false, message: '请输入医生情况描述！', },
  ]
})

const bigTypeSlectOptions =ref([])

const initBigTypeSelectList=async()=>{
  console.log('xxx')
  const res=await axios.post("admin/bigType/listAll");
  bigTypeSlectOptions.value=res.data.bigTypeList;
}

initBigTypeSelectList();

const handleConfirm = () => {
  formRef.value.validate(async (valid) => {
    console.log('确认???')
    // 添加医生
    if (valid && props.id == -1) {
      console.log('确认!!!')
       try{
        let result=await axios.post("township-user/addTownshipUser",form.value);
        let data=result.data;
         console.log(data)
        if(data.code==0){
          ElMessage.success("执行成功");
          formRef.value.resetFields();
          emits("initSmallTypeList")
          handleClose();
        }else{
          ElMessage.error(data.msg);
        }
      }catch(err){
        console.log("error:"+err)
        ElMessage.error('系统运行出错，请联系管理员');
      }
    }
    // 修改医生信息
    else if (valid && props.id != -1) {
      console.log(props.id)
      console.log('确认不是-1!!!')
      try{
        let result=await axios.post("township-user/update",form.value);
        let data=result.data;
        console.log(data)
        if(data.code==0){
          ElMessage.success("执行成功");
          formRef.value.resetFields();
          emits("initSmallTypeList")
          handleClose();
          //确认修改好信息后
          // console.log(props.id)
          // props.id = -1
          // console.log(props.id)
        }else{
          ElMessage.error(data.msg);
        }
      }catch(err){
        console.log("error:"+err)
        ElMessage.error('系统运行出错，请联系管理员');
      }
    }
    else {
      return false
    }
  })
}
</script>

<style scoped>

</style>